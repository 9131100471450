import React, { useEffect, useReducer } from "react";
import { BrowserRouter } from "react-router-dom";

// material-ui
import CssBaseline from "@mui/material/CssBaseline";
import StyledEngineProvider from "@mui/material/StyledEngineProvider";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import createTheme from "@mui/material/styles/createTheme";
import ThemeRoutes from "./routes";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import AuthService from "./services/AuthService";
import ActiveEntity from "./modules/myEntity/ActiveEntity";
import { useMyAccountService } from "./modules/myAccount/useMyAccountService";

export const fp = (async () => {
  const fp = await FingerprintJS.load();
  return await fp.get();
})();

export const AuthContext = React.createContext();
export const EntityContext = React.createContext();

const themeOptions = {
  direction: "ltr",
  // palette: themePalette(themeOption),
  mixins: {
    toolbar: {
      minHeight: "48px",
      padding: "16px",
      "@media (min-width: 600px)": {
        minHeight: "48px",
      },
    },
  },
  // typography: themeTypography(themeOption)
};

const theme = createTheme(themeOptions);

const initialState = {
  token: null,
  authUser: null,
  isAuthenticated: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      return {
        ...state,
        isAuthenticated: true,
        authUser: action.payload.authUser,
        token: action.payload.token,
      };
    case "LOGOUT":
      return {
        ...state,
        isAuthenticated: false,
        authUser: null,
        token: null,
      };
    case "UPDATE":
      return {
        ...state,
        authUser: action.payload.authUser,
      };
    default:
      return state;
  }
};

function App() {
  const [authState, dispatch] = useReducer(reducer, initialState);

  const { authenticate } = useMyAccountService();

  useEffect(() => {
    AuthService.checkAuth().then((res) => {
      if (res.status === "success") {
        authenticate({ token: res.data.token }).then((res) => {
          if (res.status === "success") {
            dispatch({
              type: "LOGIN",
              payload: {
                authUser: res.data,
                token: res.data.token,
              },
            });
          } else {
            dispatch({
              type: "LOGOUT",
            });
          }
        });
      } else {
        dispatch({ type: "LOGOUT" });
      }
    });
  }, []);

  return (
    <AuthContext.Provider
      value={{
        authState,
        dispatch,
      }}
    >
      <ActiveEntity>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <BrowserRouter>
              <ThemeRoutes />
            </BrowserRouter>
          </ThemeProvider>
        </StyledEngineProvider>
      </ActiveEntity>
    </AuthContext.Provider>
  );
}

export default App;
