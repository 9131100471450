import { useCallback } from "react";
import { httpFetch } from "../../services/AdminApiService";
import { fp } from "../../App";

const useMyAccountService = () => {
  const authenticate = useCallback(async (params) => {
    const config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        FpId: (await fp).visitorId,
      },
      body: JSON.stringify(params),
    };
    return await httpFetch("/authenticate", config);
  }, []);

  return {
    authenticate,
  };
};

export { useMyAccountService };
