import { lazy, useContext, useEffect } from "react";
import { useNavigate, useRoutes } from "react-router-dom";
import { EntityContext } from "../App";
import Loadable from "../components/loadable/Loadable";
import config from "../config";
import AdminRoutes from "./AdminRoutes";
// const DeleteAccount = Loadable(lazy(() => import("../views/account/DeleteAccount")));
const Login = Loadable(lazy(() => import("../modules/login/Login")));

export default function ThemeRoutes() {
  const { can, activeEntity } = useContext(EntityContext);

  const navigate = useNavigate();

  useEffect(() => {
    // redirect to dashboard if user has activeEntity.
    // This is also where you can check for role and redirect to the appropriate page
    if (activeEntity && activeEntity._id) {
      return navigate("/admin/dashboard/establishments", { replace: true });
    } else {
      return navigate("/", { replace: true });
    }
  }, [activeEntity]);

  return useRoutes(
    [
      AdminRoutes(can),
      {
        path: "/",
        element: <Login />,
      },
    ],
    config.basename
  );
}
