import React, { useContext, useEffect, useState } from "react";
import { AuthContext, EntityContext } from "../../App";
import { useMyEntityService } from "./useMyEntityService";

export default function ActiveEntity(props) {
  const { authState } = useContext(AuthContext);
  const { myEntity, findPermissions } = useMyEntityService();
  const [activeEntity, setActiveEntity] = useState({});
  const [authPermissionList, setAuthPermissionList] = useState([]);

  useEffect(() => {
    if (authState.isAuthenticated) {
      // Find the active entity
      myEntity().then((res) => {
        if (res.status === "success") {
          setActiveEntity(res.data[0]);
        }
      });
    }
  }, [authState]);

  useEffect(() => {
    if (activeEntity && activeEntity._id) {
      findPermissions({
        entityId: activeEntity._id,
      }).then((res) => {
        if (res.status === "success") {
          setAuthPermissionList(res.data);
        }
      });
    }
  }, [activeEntity]);

  const can = (route) => {
    if (authPermissionList.includes(route)) {
      return true;
    }
    return false;
  };

  return (
    <EntityContext.Provider value={{ can, activeEntity, setActiveEntity }}>{props.children}</EntityContext.Provider>
  );
}
