import { useCallback, useContext } from "react";
import { httpFetch } from "../../services/AdminApiService";
import { AuthContext, fp } from "../../App";

const useMyEntityService = () => {
  const { authState } = useContext(AuthContext);

  const myEntity = useCallback(async () => {
    const config = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authState.token}`,
        "Content-Type": "application/json",
        FpId: (await fp).visitorId,
      },
    };
    return await httpFetch("/myEntity", config);
  }, [authState.token]);

  const findPermissions = useCallback(
    async (params) => {
      const config = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${authState.token}`,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
        body: JSON.stringify(params),
      };
      return await httpFetch("/myEntity/permissions", config);
    },
    [authState.token]
  );

  return {
    myEntity,
    findPermissions,
  };
};

export { useMyEntityService };
